import React, { useReducer } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './scss/style.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GlobalContext from './context/globalContext';
import globalReducer, { initialState } from './reducers/globalReducer';
import {
  AccountPage,
  UsersPage,
  DevicesPage,
  LoginPage,
  PollsPage,
  RatingPage,
  ReportsPage,
  ResetPasswordPage,
  StatisticPage,
  NotFoundPage,
  ServerErrorPage,
  QrVotePage,
  HelpPage,
  ReviewsPage,
  BackStagePage
} from './Layouts';
import FirstPage from './Layouts/FirstPage';
import DeviceEdit from './components/Devices/DeviceEdit';
import UserEdit from './components/Users/UserEdit';
import PollEdit from './components/Polls/PollEdit';
import NewOrEditReports from './components/Reports/NewOrEditReport';
import HelpModal from './components/Modals/HelpModal';
import PrivacyTerms from './Layouts/PrivacyTerms';

const App = () => {
  const [state, dispatch] = useReducer(globalReducer, initialState);
  const isOwnerAdmin = state.user?.is_owner || state.user?.is_admin;
  const userHasHardware = state.user?.firm?.has_hardware;
  return (
    <GlobalContext.Provider
      value={{ dispatchGlobal: dispatch, stateGlobal: state }}
    >
      <ToastContainer />
      {localStorage.getItem('token') ? (
        <Router>
          <Switch>
            <Route path="/" exact component={StatisticPage} />
            <Route path="/account" exact component={AccountPage} />
            {isOwnerAdmin && (
              <Route path="/users" exact component={UsersPage} />
            )}
            {/* <Route path="/faq" exact component={HelpPage} /> */}
            {isOwnerAdmin && (
              <Route
                path="/users/addUser"
                exact
                render={(props) => <UserEdit {...props} createNewUser />}
              />
            )}
            <Route path="/device/vote/:uuid" exact component={QrVotePage} />
            {isOwnerAdmin && (
              <Route path="/users/:userId" exact component={UserEdit} />
            )}
            <Route path="/rating" exact component={RatingPage} />
            <Route path="/polls" exact component={PollsPage} />
            {isOwnerAdmin && (
              <Route
                path="/polls/pollCreate"
                exact
                render={(props) => <PollEdit {...props} createNewPoll />}
              />
            )}
            {isOwnerAdmin && (
              <Route path="/polls/:pollId" exact component={PollEdit} />
            )}
            {userHasHardware && (
              <Route path="/devices" exact component={DevicesPage} />
            )}
            {isOwnerAdmin && userHasHardware && (
              <Route
                path="/device/:singleDeviceId"
                exact
                component={DeviceEdit}
              />
            )}
            <Route path="/reports" exact component={ReportsPage} />
            <Route
              path="/reports/create"
              exact
              render={(props) => (
                <NewOrEditReports {...props} createNewReport />
              )}
            />
            <Route
              path="/reports/:reportId"
              exact
              component={NewOrEditReports}
            />
            <Route path="/reviews" exact component={ReviewsPage} />
            <Route path="/backstage" exact component={BackStagePage} />
            <Route path="/errorPage" exact component={ServerErrorPage} />
            <Route component={NotFoundPage} />
          </Switch>
          <HelpModal show={state.helpDeskModal} />
        </Router>
      ) : (
        <Router>
          <Switch>
            <Route path="/backstage" exact component={BackStagePage} />
            <Route path="/policy" exact component={PrivacyTerms} />
            <Route
              path="/terms"
              exact
              render={(props) => <PrivacyTerms {...props} terms />}
            />
            <Route
              path="/resetPassword/:resetToken"
              exact
              component={ResetPasswordPage}
            />
            <Route path="/device/vote/:uuid" exact component={QrVotePage} />
            <Route path="/errorPage" exact component={ServerErrorPage} />
            <Route path="/login" component={LoginPage} />
            <Route path="/" exact component={FirstPage} />
          </Switch>
        </Router>
      )}
    </GlobalContext.Provider>
  );
};

export default App;
