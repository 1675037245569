import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Map } from 'react-lodash';
import ServiceAPI from '../../services';
import GlobalContext from '../../context/globalContext';
import PageContent from '../StyleComponents/PageContent';
import PageFilters from '../PageFilters/PageFilters';
import FilterByPolls from '../PageFilters/FilterByPolls';
import useDates from '../../hooks/useDates';
import useFilterByPolls from '../../hooks/useFilterByPolls';
import DropDown from './DropDown';
import Pagination from '../Pagination/Pagination';
import usePagination from '../../hooks/usePagination';
import RatingRowNps from './RatingRowNps';
import RatingRowCsi from './RatingRowCsi';
import RatingRowAnswers from './RatingRowAnswers';
import { ReactComponent as IconFilter } from '../../img/sprite/svg/filter-rating.svg';
import { ReactComponent as IconSort } from '../../img/sprite/svg/sort.svg';
import { loading } from '../../actions';
import Loading from '../Loading/Loading';
import GraphicModal from '../Modals/GraphicModal';
import StatisticStart from '../Statistic/StatisticStart';
import { datesDiff } from '../../usefulFunctions';

const Rating = () => {
  const { t } = useTranslation();
  const service = new ServiceAPI();
  const { dispatchGlobal, stateGlobal } = useContext(GlobalContext);

  const answersLocationOptions = [
    { value: 'polls', label: t('ratingPage.polls') },
    { value: 'address', label: t('ratingPage.address') }
  ];

  const npsCsiAnswersOptions = [
    { value: 'nps', label: 'NPS' },
    { value: 'csat', label: 'CSAT' },
    { value: 'total', label: t('ratingPage.answers') }
  ];

  const [sortByAnswersLocation, setSortByAnswersLocation] = useState(
    answersLocationOptions[0]
  );
  const [sortByNpsCsiAnswers, setSortByNpsCsiAnswers] = useState(
    npsCsiAnswersOptions[0]
  );
  const [bigGraph, setbigGraph] = useState({ show: false });

  const [reports, setReports] = useState(null);
  const [count, setCount] = useState(0);
  const {
    next,
    prev,
    jump,
    currentPage,
    setCurrentPage,
    maxPage
  } = usePagination(count, process.env.REACT_APP_PAGINATION_NUM);

  const { dates, setDates, datesSetter } = useDates();
  const {
    showFilter,
    setShowFilter,
    shortAllQuizzes,
    options,
    selected,
    allPollsCheckBox,
    changeOrganization,
    showAllPolls,
    lastSearch,
    setLastSearch,
    firstRenderSearch
  } = useFilterByPolls();

  const currentDates = {
    date_before: dates.to.utc().format().replace('Z', ''),
    date_after: dates.from.utc().format().replace('Z', '')
  };

  const trunc = datesDiff(dates.to, dates.from);

  const dataForBack = () => {
    const idIn = selected.map((item) => item.value);
    return {
      ...currentDates,
      ordering: `-${[sortByNpsCsiAnswers.value]}`,
      quiz_id__in: idIn,
      trunc
    };
  };

  useEffect(() => {
    dispatchGlobal(loading(true));
    if (sortByAnswersLocation.value === 'polls') {
      service.getRaitingsByQuizzes(dataForBack()).then((resp) => {
        if (resp.status === 200) {
          setCount(resp.data.count);
          setReports(resp.data.results);
          setCurrentPage(1);
          dispatchGlobal(loading(false));
        }
      });
    } else {
      service.getRaitingsByAddress(dataForBack()).then((resp) => {
        if (resp.status === 200) {
          setCount(resp.data.count);
          setReports(resp.data.results);
          setCurrentPage(1);
          dispatchGlobal(loading(false));
        }
      });
    }
  }, [sortByAnswersLocation, sortByNpsCsiAnswers, dates]);

  const changeFilters = () => {
    dispatchGlobal(loading(true));
    if (sortByAnswersLocation.value === 'polls') {
      service.getRaitingsByQuizzes(dataForBack()).then((resp) => {
        if (resp.status === 200) {
          setCount(resp.data.count);
          setReports(resp.data.results);
          setCurrentPage(1);
          dispatchGlobal(loading(false));
        }
      });
    } else {
      service.getRaitingsByAddress(dataForBack()).then((resp) => {
        if (resp.status === 200) {
          setCount(resp.data.count);
          setReports(resp.data.results);
          setCurrentPage(1);
          dispatchGlobal(loading(false));
        }
      });
    }
    const oldFilters = JSON.parse(localStorage.getItem('filters'));
    const newFilters = {
      ...oldFilters,
      selectedOptions: selected
    };
    localStorage.setItem('filters', JSON.stringify(newFilters));
  };

  const changePage = (pageNum) => {
    dispatchGlobal(loading(true));
    const params = {
      ...currentDates,
      ordering: `-${[sortByNpsCsiAnswers.value]}`
    };
    if (currentPage !== pageNum) {
      if (sortByAnswersLocation.value === 'polls') {
        service
          .getRaitingsByQuizzes(
            pageNum > 1
              ? {
                  ...dataForBack(),
                  limit: process.env.REACT_APP_PAGINATION_NUM,
                  offset:
                    pageNum * process.env.REACT_APP_PAGINATION_NUM -
                    process.env.REACT_APP_PAGINATION_NUM
                }
              : dataForBack()
          )
          .then((resp) => {
            if (resp.status === 200) {
              setReports(resp.data.results);
              dispatchGlobal(loading(false));
            }
          });
      } else {
        service
          .getRaitingsByAddress(
            pageNum > 1
              ? {
                  ...params,
                  limit: process.env.REACT_APP_PAGINATION_NUM,
                  offset:
                    pageNum * process.env.REACT_APP_PAGINATION_NUM -
                    process.env.REACT_APP_PAGINATION_NUM
                }
              : params
          )
          .then((resp) => {
            if (resp.status === 200) {
              setReports(resp.data.results);
              dispatchGlobal(loading(false));
            }
          });
      }
    }
  };

  const showGraphic = (range, type, otherRanges) => {
    setbigGraph({
      show: true,
      range,
      type,
      otherRanges
    });
  };

  return (
    <PageContent>
      <div className="content__header">
        <h1 className="content__title">{t('ratingPage.pageName')}</h1>
        <div className="content__header-options">
          <PageFilters
            dates={dates}
            setDates={setDates}
            datesSetter={datesSetter}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            changeFilters={changeFilters}
            pollsOptions={options}
            selectedPolls={selected}
          />
        </div>
      </div>
      <FilterByPolls
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        shortAllQuizzes={shortAllQuizzes}
        options={options}
        selected={selected}
        allPollsCheckBox={allPollsCheckBox}
        changeOrganization={changeOrganization}
        showAllPolls={showAllPolls}
        lastSearch={lastSearch.quizzes}
        setLastSearch={setLastSearch}
        changeFilters={changeFilters}
        firstRenderSearch={firstRenderSearch?.quizzes}
      />
      <div className="content__panels">
        <div className="rating">
          <div className="rating__top">
            <div className="rating__title">
              {t('ratingPage.bestPerformers')}
            </div>
            <div className="rating__filters">
              <DropDown
                value={sortByAnswersLocation}
                options={answersLocationOptions}
                placeholder={t('ratingPage.sortByPolls')}
                text={t('ratingPage.sortBy')}
                onChange={(e) => setSortByAnswersLocation(e)}
              />
              <DropDown
                value={sortByNpsCsiAnswers}
                options={npsCsiAnswersOptions}
                placeholder={t('ratingPage.filterByNps')}
                text={t('ratingPage.filterBy')}
                onChange={(e) => setSortByNpsCsiAnswers(e)}
              />
              <IconFilter className="svg-sprite-icon icon-filter-rating" />
            </div>
          </div>
          {reports && reports.length === 0 ? (
            <StatisticStart noData />
          ) : (
            <div className="rating__panel">
              <div className="rating-panel">
                {sortByNpsCsiAnswers.value !== 'total' ? (
                  <div className="rating-panel__head">
                    <div className="col-3 rating-panel__col-main">
                      {sortByAnswersLocation.value === 'polls'
                        ? t('ratingPage.polls')
                        : t('ratingPage.address')}
                    </div>
                    <div className="col-2 text-center">
                      {t('ratingPage.answers')}
                    </div>
                    <div className="col-2 text-center">{t('ratingPage.trend')}</div>
                    <div className="col-2 text-center">
                      {`${
                        sortByNpsCsiAnswers.value === 'nps' ? 'NPS' : 'CSAT'
                      } ${t('ratingPage.trend')}`}
                    </div>
                    <div className="col-2 text-center">
                      {sortByNpsCsiAnswers.value === 'nps' ? 'NPS' : 'CSAT'}
                    </div>
                    <div className="col-1 text-center">№</div>
                  </div>
                ) : (
                  <div className="rating-panel__head">
                    <div className="col-3 rating-panel__col-main">
                      {sortByAnswersLocation.value === 'polls'
                        ? t('ratingPage.polls')
                        : t('ratingPage.address')}
                    </div>
                    <div className="col-3 text-center">{t('ratingPage.trend')}</div>
                    <div className="col-3 text-center">
                      {t('ratingPage.answers')}
                    </div>
                    <div className="col-3 text-center">№</div>
                  </div>
                )}
                {!stateGlobal.isLoading ? (
                  <Map
                    collection={reports}
                    iteratee={(item, index) => {
                      if (sortByNpsCsiAnswers.value === 'nps') {
                        return (
                          <RatingRowNps
                            key={index}
                            item={item}
                            index={index}
                            currentPage={currentPage}
                            pollsOrAddress={sortByAnswersLocation.value}
                            npsCsiAnswers={sortByNpsCsiAnswers.value}
                            showGraphic={showGraphic}
                            trunc={trunc}
                          />
                        );
                      }
                      if (sortByNpsCsiAnswers.value === 'csat') {
                        return (
                          <RatingRowCsi
                            key={index}
                            item={item}
                            index={index}
                            currentPage={currentPage}
                            pollsOrAddress={sortByAnswersLocation.value}
                            npsCsiAnswers={sortByNpsCsiAnswers.value}
                            showGraphic={showGraphic}
                            trunc={trunc}
                          />
                        );
                      }
                      return (
                        <RatingRowAnswers
                          key={index}
                          item={item}
                          index={index}
                          currentPage={currentPage}
                          pollsOrAddress={sortByAnswersLocation.value}
                          npsCsiAnswers={sortByNpsCsiAnswers.value}
                          showGraphic={showGraphic}
                          trunc={trunc}
                        />
                      );
                    }}
                  />
                ) : (
                  <Loading />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="content__pagination">
        <Pagination
          currentPage={currentPage}
          maxPage={maxPage}
          next={next}
          prev={prev}
          jump={jump}
          changePage={changePage}
        />
      </div>

      <GraphicModal
        show={bigGraph.show}
        range={bigGraph.range}
        type={bigGraph.type}
        otherRanges={bigGraph.otherRanges}
        trunc={trunc}
        onHide={() => setbigGraph({ ...bigGraph, show: false })}
      />
    </PageContent>
  );
};

export default Rating;
