import React from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

const customStyles = {
  control: (provided) => {
    const isMobile = window.innerWidth < 731;
    return {
      ...provided,
      backgroundColor: '#fff',
      border: 'solid 1px #e3e7ee',
      borderRadius: '3px',
      minHeight: 'unset',
      height: 'auto',
      cursor: 'pointer',
      boxShadow: 'none',
      width: isMobile ? '210px' : 'auto',
      padding: '4px',
      ':hover': {
        border: 'solid 1px #e3e7ee',
        boxShadow: 'none'
      },
      ':focus-within': {
        border: 'solid 1px e3e7ee',
        boxShadow: '0 0 0 1.5px rgba(116, 173, 230, 0.25)'
      }
    };
  },
  valueContainer: (provided) => {
    const isMobile = window.innerWidth < 731;
    return {
      ...provided,
      justifyContent: isMobile ? 'center' : 'start',
      padding: isMobile ? '6.5px 9px 6.5px' : '0px 8px'
    };
  },
  input: (provided) => ({
    ...provided,
    margin: '0px'
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '0px'
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: '0px'
  })
};

const starOptions = [
  { value: '4,5', label: 'All' },
  { value: 4, label: 4 },
  { value: 5, label: 5 }
];

const FilterByStars = ({ selectedStars, handleStarClick }) => {
  const { t } = useTranslation();

  const renderStars = (rating) => {
    if (!isNaN(parseFloat(rating)) && isFinite(rating)) {
      return (
        <span>
          {[...Array(5)].map((_, index) => (
            <i
              key={index}
              className={`bi ${
                index < rating
                  ? 'bi-star-fill text-warning'
                  : 'bi-star-fill text-secondary'
              }`}
            ></i>
          ))}
        </span>
      );
    }
    return <span>{t('ReviewsPage.all')}</span>;
  };

  const CustomOption = (props) => {
    const { data, innerRef, innerProps, isSelected, isFocused } = props;
    return (
      <div
        ref={innerRef}
        {...innerProps}
        style={{
          padding: '10px',
          fontSize: '14px',
          fontWeight: '400',
          letterSpacing: '0.7px',
          color: isSelected ? '#ffffff' : '#a8b3c2',
          cursor: 'pointer',
          backgroundColor: isSelected
            ? '#74ADE6'
            : isFocused
            ? '#cce5ff'
            : 'white'
        }}
      >
        {renderStars(data.label)}
      </div>
    );
  };

  const CustomSingleValue = ({ data }) => {
    return (
      <div style={{ fontSize: '0.875rem' }}>
        Rating stars:{' '}
        <span
          style={{
            backgroundColor: '#a8b3c2',
            color: 'white',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            padding: '0px 5px',
            borderRadius: '4px'
          }}
        >
          {data.label === 'All' ? t('ReviewsPage.all') : data.value}
        </span>
      </div>
    );
  };

  return (
    <div className="content__header-stars">
      <Select
        options={starOptions}
        components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
        defaultValue={starOptions[0]}
        styles={customStyles}
        isSearchable={false}
        onChange={handleStarClick}
        value={starOptions.filter((item) => item.value === selectedStars)}
      />
    </div>
  );
};

export default FilterByStars;
