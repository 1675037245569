import React from 'react';
import PropTypes from 'prop-types';
import logoSended from '../../img/qr-sended.png';
import QrFirmLogo from './QrFirmLogo';
import QrFooter from './QrFooter';
import translation from './translation.json';

const FewLinksSuccessVoteQr = ({ links, image, developedIn, lang }) => {
  return (
    <>
      <div className="container text-center">
        <QrFirmLogo image={image} />
        <img
          className="page-qr__few-links-sended-img"
          src={logoSended}
          alt=""
        />
        <div className="page-qr__sended-subtitle-container">
          <p className="page-qr__sended-subtitle">
            {translation.message[lang]}
          </p>
        </div>
        <p className="page-qr__few-links-subtitle">
          {translation.openLink[lang]}:
        </p>
        {links.map((value, index) => {
          return (
            <a key={index} className="page-qr__few-links-link" href={value.url}>
              {value.name}
            </a>
          );
        })}
      </div>
      <QrFooter developedIn={developedIn} />
    </>
  );
};

export default FewLinksSuccessVoteQr;

FewLinksSuccessVoteQr.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({})),
  image: PropTypes.shape({}),
  developedIn: PropTypes.string,
  lang: PropTypes.string
};

FewLinksSuccessVoteQr.defaultProps = {
  links: null,
  image: null,
  developedIn: '',
  lang: 'en'
};
