import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Datetime from 'react-datetime';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import useOutsideClick from '../../hooks/useOutsideClick';
import { ReactComponent as IconCalendar } from '../../img/sprite/svg/calendar.svg';
import { ReactComponent as IconFilter } from '../../img/sprite/svg/filter.svg';
import { ReactComponent as IconRefresh } from '../../img/sprite/svg/refresh.svg';

const PageFilters = ({
  dates,
  setDates,
  datesSetter,
  showFilter,
  setShowFilter,
  changeFilters,
  pollsOptions,
  selectedPolls
}) => {
  const { t, i18n } = useTranslation();
  require(`moment/locale/ru`);
  require(`moment/locale/uk`);
  require(`moment/locale/pl`);
  const langObj = {
    ukr: 'uk',
    ru: 'ru',
    en: 'en',
    pl: 'pl'
  };
  const locale = langObj[i18n.language];
  const [showDropDownMenu, setshowDropDownMenu] = useState(false);
  const openDropDown = useRef();
  const pollsIsChanged = JSON.parse(
    localStorage.getItem('filters')
  )?.pollsIsChanged;

  useOutsideClick(openDropDown, () => setshowDropDownMenu(false));

  useEffect(() => {
    const timer = setTimeout(() => {
      const oldFilters = JSON.parse(localStorage.getItem('filters'));

      const pollsIsChanged = pollsOptions?.length !== selectedPolls?.length;
      const newFilters = { ...oldFilters, pollsIsChanged };

      localStorage.setItem('filters', JSON.stringify(newFilters));
    }, 500);

    return () => clearTimeout(timer);
  }, [showFilter, pollsOptions, selectedPolls]);

  const datesAudit = (fromOrTo, date) => {
    if (fromOrTo === 'from' && date.isAfter(dates.to, 'day')) {
      setDates({
        from: moment(date).add(1, 'm'),
        to: date,
        datesSelect: { date: 'manual', time: moment() }
      });
    } else if (fromOrTo === 'to' && date.isBefore(dates.from, 'day')) {
      setDates({
        from: date,
        to: moment(date).subtract(1, 'm'),
        datesSelect: { date: 'manual', time: moment() }
      });
    } else {
      setDates({
        ...dates,
        [fromOrTo]: date,
        datesSelect: { date: 'manual', time: moment() }
      });
    }
  };

  return (
    <>
      <div className="content__header-datepicker">
        <div className="page-datepicker">
          <div className="page-datepicker__input-container">
            <div className="page-datepicker__input">
              <Datetime
                locale={locale}
                timeFormat={false}
                value={dates.from.local()}
                onChange={(e) => datesAudit('from', moment(e))}
                closeOnSelect
                isValidDate={(current) =>
                  current.isSameOrBefore(moment(), 'day')
                }
                inputProps={{ readOnly: true }}
              />
            </div>
          </div>
          <div className="page-datepicker__input-container">
            <div className="page-datepicker__input">
              <Datetime
                locale={locale}
                timeFormat={false}
                value={dates.to.local()}
                onChange={(e) => datesAudit('to', moment(e))}
                closeOnSelect
                isValidDate={(current) =>
                  current.isSameOrBefore(moment(), 'day')
                }
                inputProps={{ readOnly: true }}
              />
            </div>
          </div>
        </div>
      </div>
      <div ref={openDropDown} className="content__header-filters">
        <div className="date-dropdown dropdown">
          <button
            className="btn btn-filter d-flex justify-content-center align-items-center"
            type="button"
            onClick={() => setshowDropDownMenu(!showDropDownMenu)}
            data-toggle="dropdown"
            data-display="static"
            aria-haspopup="true"
            aria-expanded={showDropDownMenu ? 'true' : 'false'}
          >
            <IconCalendar className="svg-sprite-icon icon-calendar" />
          </button>

          <div
            className={
              showDropDownMenu
                ? 'dropdown-menu dropdown-menu-right show'
                : 'dropdown-menu dropdown-menu-right '
            }
          >
            <span
              className={`dropdown-item ${
                dates.datesSelect.date === 'today' && 'is-active'
              }`}
              onClick={() => {
                datesSetter('today');
                setshowDropDownMenu(false);
              }}
            >
              {t('statisticPage.today')}
            </span>
            <span
              className={`dropdown-item ${
                dates.datesSelect.date === 'yestoday' && 'is-active'
              }`}
              onClick={() => {
                datesSetter('yestoday');
                setshowDropDownMenu(false);
              }}
            >
              {t('statisticPage.yesterday')}
            </span>
            <span
              className={`dropdown-item ${
                dates.datesSelect.date === 'last7Days' && 'is-active'
              }`}
              onClick={() => {
                datesSetter('last7Days');
                setshowDropDownMenu(false);
              }}
            >
              {t('statisticPage.last7Days')}
            </span>
            <span
              className={`dropdown-item ${
                dates.datesSelect.date === 'last14Days' && 'is-active'
              }`}
              onClick={() => {
                datesSetter('last14Days');
                setshowDropDownMenu(false);
              }}
            >
              {t('statisticPage.last14Days')}
            </span>
            <span
              className={`dropdown-item ${
                dates.datesSelect.date === 'last30Days' && 'is-active'
              }`}
              onClick={() => {
                datesSetter('last30Days');
                setshowDropDownMenu(false);
              }}
            >
              {t('statisticPage.last30Days')}
            </span>
            <span
              className={`dropdown-item ${
                dates.datesSelect.date === 'thisWeek' && 'is-active'
              }`}
              onClick={() => {
                datesSetter('thisWeek');
                setshowDropDownMenu(false);
              }}
            >
              {t('statisticPage.thisWeek')}
            </span>
            <span
              className={`dropdown-item ${
                dates.datesSelect.date === 'lastWeek' && 'is-active'
              }`}
              onClick={() => {
                datesSetter('lastWeek');
                setshowDropDownMenu(false);
              }}
            >
              {t('statisticPage.lastWeek')}
            </span>
            <span
              className={`dropdown-item ${
                dates.datesSelect.date === 'thisMonth' && 'is-active'
              }`}
              onClick={() => {
                datesSetter('thisMonth');
                setshowDropDownMenu(false);
              }}
            >
              {t('statisticPage.thisMonth')}
            </span>
            <span
              className={`dropdown-item ${
                dates.datesSelect.date === 'lastMonth' && 'is-active'
              }`}
              onClick={() => {
                datesSetter('lastMonth');
                setshowDropDownMenu(false);
              }}
            >
              {t('statisticPage.lastMonth')}
            </span>
          </div>
        </div>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>{t('statisticPage.filterSettings')}</Tooltip>}
        >
          <button
            className="btn btn-filter content__header-btn d-flex justify-content-center align-items-center"
            type="button"
            data-toggle="collapse"
            data-target="#collapseFilter"
            aria-expanded={showFilter || pollsIsChanged ? 'true' : 'false'}
            aria-controls="collapseFilter"
            onClick={() => {
              setShowFilter(!showFilter);
            }}
          >
            <IconFilter className="svg-sprite-icon icon-filter" />
          </button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>{t('statisticPage.refreshData')}</Tooltip>}
        >
          <button
            type="button"
            className="btn btn-filter content__header-btn d-flex justify-content-center align-items-center"
            onClick={() => changeFilters()}
          >
            <IconRefresh className="svg-sprite-icon icon-filter" />
          </button>
        </OverlayTrigger>
      </div>
    </>
  );
};

export default PageFilters;
