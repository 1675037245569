import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Map } from 'react-lodash';
import moment from 'moment';
import ServiceAPI from '../../services';
import GlobalContext from '../../context/globalContext';
import PageContent from '../StyleComponents/PageContent';
import useDates from '../../hooks/useDates';
import StatisticStart from './StatisticStart';
import Pagination from '../Pagination/Pagination';
import usePagination from '../../hooks/usePagination';
import useFilterByPolls from '../../hooks/useFilterByPolls';
import StatisticRow from './StatisticRow';
import '../../сss/react-datetime.css';
import PageFilters from '../PageFilters/PageFilters';
import GraphicModal from '../Modals/GraphicModal';
import AdditionalAnswersModal from '../Modals/AdditionalAnswersModal';
import FilterByPolls from '../PageFilters/FilterByPolls';
import Loading from '../Loading/Loading';
import { loading } from '../../actions';
import { datesDiff } from '../../usefulFunctions';

const Statistic = () => {
  const { t } = useTranslation();
  const service = new ServiceAPI();
  const { dispatchGlobal, stateGlobal } = useContext(GlobalContext);

  const { dates, setDates, datesSetter } = useDates();
  const [bigGraph, setbigGraph] = useState({ show: false });
  const [additionalAnswersGraph, setAdditionalAnswersGraph] = useState({
    show: false
  });
  const [polls, setPolls] = useState([]);
  const [count, setCount] = useState(0);
  const {
    next,
    prev,
    jump,
    currentPage,
    setCurrentPage,
    maxPage
  } = usePagination(count, process.env.REACT_APP_PAGINATION_NUM);

  const {
    showFilter,
    setShowFilter,
    shortAllQuizzes,
    options,
    selected,
    allPollsCheckBox,
    setAllPollsCheckBox,
    changeOrganization,
    showAllPolls,
    lastSearch,
    setLastSearch,
    firstRenderSearch
  } = useFilterByPolls();

  const trunc = datesDiff(dates.to, dates.from);

  const dataForBack = () => {
    const idIn = selected.map((item) => item.value);

    const currentDates = {
      date_before: moment(dates.to).utc().format().replace('Z', ''),
      date_after: moment(dates.from).utc().format().replace('Z', '')
    };

    return allPollsCheckBox
      ? {
          ...currentDates,
          quiz_id__in: idIn,
          is_total: true,
          trunc
        }
      : {
          ...currentDates,
          quiz_id__in: idIn,
          trunc
        };
  };

  useEffect(() => {
    dispatchGlobal(loading(true));
    service.getRaitingsByQuizzes(dataForBack()).then((resp) => {
      if (resp.status === 200) {
        setCount(resp.data.count);
        setPolls(resp.data.results);
        setCurrentPage(1);
        dispatchGlobal(loading(false));
      }
    });
  }, [dates]);

  const changeFilters = () => {
    // const idIn = selected.map((item) => item.value);
    dispatchGlobal(loading(true));
    service.getRaitingsByQuizzes(dataForBack()).then((resp) => {
      if (resp.status === 200) {
        setCount(resp.data.count);
        setPolls(resp.data.results);
        setCurrentPage(1);
        dispatchGlobal(loading(false));

        const oldFilters = JSON.parse(localStorage.getItem('filters'));
        const newFilters = {
          ...oldFilters,
          allPollsCheckBox,
          selectedOptions: selected
        };
        localStorage.setItem('filters', JSON.stringify(newFilters));
      }
    });
  };

  const changePage = (pageNum) => {
    // const idIn = selected.map((item) => item.value);
    dispatchGlobal(loading(true));
    if (currentPage !== pageNum) {
      service
        .getRaitingsByQuizzes(
          pageNum > 1
            ? {
                ...dataForBack(),
                limit: process.env.REACT_APP_PAGINATION_NUM,
                offset:
                  pageNum * process.env.REACT_APP_PAGINATION_NUM -
                  process.env.REACT_APP_PAGINATION_NUM
              }
            : dataForBack()
        )
        .then((resp) => {
          if (resp.status === 200) {
            setPolls(resp.data.results);
            dispatchGlobal(loading(false));
          }
        });
    }
  };

  const showGraphic = (range, type, otherRanges) => {
    setbigGraph({
      show: true,
      range,
      type,
      otherRanges
    });
  };

  const showAdditionalAnswersGraph = (quizId, questionId, pollData) => {
    setAdditionalAnswersGraph({ show: true, quizId, questionId, pollData });
  };

  if (stateGlobal.isLoading) {
    return (
      <PageContent>
        <div className="content__header">
          <h1 className="content__title">{t('statisticPage.pageName')}</h1>
          <div className="content__header-options">
            <PageFilters
              dates={dates}
              setDates={setDates}
              datesSetter={datesSetter}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              pollsOptions={options}
              selectedPolls={selected}
            />
          </div>
        </div>
        <FilterByPolls
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          shortAllQuizzes={shortAllQuizzes}
          options={options}
          selected={selected}
          allPollsCheckBox={allPollsCheckBox}
          setAllPollsCheckBox={setAllPollsCheckBox}
          changeOrganization={changeOrganization}
          showAllPolls={showAllPolls}
          lastSearch={lastSearch}
          setLastSearch={setLastSearch}
          changeFilters={changeFilters}
          firstRenderSearch={firstRenderSearch}
        />
        <Loading />
      </PageContent>
    );
  }
  return (
    <PageContent>
      <div className="content__header">
        <h1 className="content__title">{t('statisticPage.pageName')}</h1>
        <div className="content__header-options">
          <PageFilters
            dates={dates}
            setDates={setDates}
            datesSetter={datesSetter}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            changeFilters={changeFilters}
            pollsOptions={options}
            selectedPolls={selected}
          />
        </div>
      </div>
      <FilterByPolls
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        shortAllQuizzes={shortAllQuizzes}
        options={options}
        selected={selected}
        allPollsCheckBox={allPollsCheckBox}
        setAllPollsCheckBox={setAllPollsCheckBox}
        changeOrganization={changeOrganization}
        showAllPolls={showAllPolls}
        lastSearch={lastSearch}
        setLastSearch={setLastSearch}
        changeFilters={changeFilters}
        firstRenderSearch={firstRenderSearch}
      />
      {polls === null ? (
        <StatisticStart />
      ) : polls.length === 0 ? (
        <StatisticStart noData />
      ) : (
        <div className="content__panels">
          <Map
            collection={polls}
            iteratee={(item, index) => {
              return (
                <StatisticRow
                  key={index}
                  data={item}
                  showGraphic={showGraphic}
                  showAdditionalAnswersGraph={showAdditionalAnswersGraph}
                  currentPage={currentPage}
                  trunc={trunc}
                />
              );
            }}
          />
        </div>
      )}
      <div className="content__pagination">
        <Pagination
          currentPage={currentPage}
          maxPage={maxPage}
          next={next}
          prev={prev}
          jump={jump}
          changePage={changePage}
        />
      </div>
      <GraphicModal
        show={bigGraph.show}
        range={bigGraph.range}
        type={bigGraph.type}
        otherRanges={bigGraph.otherRanges}
        trunc={trunc}
        onHide={() => setbigGraph({ ...bigGraph, show: false })}
      />
      <AdditionalAnswersModal
        show={additionalAnswersGraph.show}
        onHide={() => setAdditionalAnswersGraph({ show: false })}
        data={additionalAnswersGraph}
      />
    </PageContent>
  );
};

export default Statistic;
