import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Select from 'react-select';
import isEqual from 'react-fast-compare';
import MainButton from '../Buttons/MainButton';
import { ReactComponent as IconRefresh } from '../../img/sprite/svg/refresh.svg';
import { ReactComponent as IconConfirm } from '../../img/sprite/svg/confirm.svg';

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: '#fff',
    border: 'solid 1px #e3e7ee',
    borderRadius: '3px',
    minHeight: '40px',
    cursor: 'pointer',
    boxShadow: 'none',
    ':hover': {
      border: 'solid 1px #e3e7ee',
      boxShadow: 'none'
    }
  }),
  multiValue: (provided) => ({
    ...provided,
    borderRadius: '3px',
    backgroundColor: '#74ade6'
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    fontSize: '14px',
    lineHeight: '1.7',
    color: '#ffffff'
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    fontSize: '14px',
    color: '#ffffff',
    ':hover': {
      backgroundColor: '#54a5f7'
    }
  }),
  valueContainer: (provided) => ({
    ...provided,
    alignItems: 'center'
  })
};

const FilterByPolls = ({
  showFilter,
  setShowFilter,
  options,
  selected,
  allPollsCheckBox,
  setAllPollsCheckBox,
  changeOrganization,
  showAllPolls,
  lastSearch,
  setLastSearch,
  changeFilters,
  firstRenderSearch
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const resettingRef = useRef(false);
  useEffect(() => {
    if (resettingRef.current) {
      resettingRef.current = false;
      changeFilters();
      setLastSearch({
        quizzes: selected,
        allPollsCheckBox
      });
    }
  }, [allPollsCheckBox, selected]);

  const saveIsactive =
    pathname !== '/rating'
      ? isEqual({ quizzes: selected, allPollsCheckBox }, lastSearch)
      : isEqual(selected, lastSearch);

  const canselIsActive = isEqual(lastSearch, firstRenderSearch);

  return (
    <div className={`collapse ${showFilter ? 'show' : ''}`} id="collapseFilter">
      <div className="filter-collapse">
        <div className="filter-collapse__top">
          <Select
            styles={customStyles}
            options={options}
            value={[...selected]}
            isMulti
            closeMenuOnSelect={false}
            onChange={changeOrganization}
            placeholder={t('statisticPage.selectPoll')}
            noOptionsMessage={() => t('statisticPage.noOptions')}
            components={{
              ClearIndicator: () => null,
              IndicatorSeparator: () => null
            }}
          />
          {pathname !== '/rating' && pathname !== '/reviews' && (
            <div className="filter-collapse__switch">
              <span>{t('statisticPage.showAll')}</span>
              <label className="page-switch">
                <input
                  type="checkbox"
                  name="name"
                  checked={allPollsCheckBox}
                  onChange={() => setAllPollsCheckBox(!allPollsCheckBox)}
                />
                <span className="page-switch__slider" />
              </label>
            </div>
          )}
        </div>
        <div className="filter-collapse__bottom">
          <MainButton
            type="button"
            className="btn btn-cancel has-icon"
            text={t('statisticPage.cancel')}
            disabled={canselIsActive}
            onClick={() => {
              resettingRef.current = true;
              showAllPolls();
              setShowFilter(!showFilter);
            }}
          >
            <span className="icon-btn">
              <IconRefresh className="svg-sprite-icon icon-refresh" />
            </span>
          </MainButton>
          <MainButton
            type="button"
            className="btn btn-confirm has-icon"
            text={t('statisticPage.save')}
            disabled={saveIsactive}
            onClick={() => {
              setLastSearch({
                quizzes: selected,
                allPollsCheckBox
              });
              changeFilters();
              setShowFilter(!showFilter);
            }}
          >
            <span className="icon-btn">
              <IconConfirm className="svg-sprite-icon icon-confirm" />
            </span>
          </MainButton>
        </div>
      </div>
    </div>
  );
};

export default FilterByPolls;

FilterByPolls.propTypes = {
  showFilter: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  selected: PropTypes.arrayOf(PropTypes.shape({})),
  allPollsCheckBox: PropTypes.bool,
  changeOrganization: PropTypes.func,
  showAllPolls: PropTypes.func,
  setShowFilter: PropTypes.func,
  lastSearch: PropTypes.shape([]),
  setLastSearch: PropTypes.func,
  changeFilters: PropTypes.func,
  setAllPollsCheckBox: PropTypes.func,
  firstRenderSearch: PropTypes.shape({})
};

FilterByPolls.defaultProps = {
  showFilter: false,
  options: null,
  selected: null,
  allPollsCheckBox: true,
  changeOrganization: null,
  showAllPolls: null,
  setShowFilter: null,
  lastSearch: null,
  setLastSearch: null,
  changeFilters: null,
  setAllPollsCheckBox: null,
  firstRenderSearch: null
};
